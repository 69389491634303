@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea {
  resize: none !important;
}

.services-table {
  width: 100%;
  border-collapse: collapse;
}

.services-table th,
.services-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}

.services-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-table th,
.payment-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}

.payment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.service-table {
  width: 100%;
  border-collapse: collapse;
}

.service-table th,
.service-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
  font-size: 12px;
}
#table-scroll {
  overflow-x:auto;
  margin-top:20px;
}
.service-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 12px;
}

.burger-icon {
  transition: transform 0.3s ease-in-out;
}

.close-icon {
  transition: opacity 0.3s ease-in-out;
}

.close-icon.open {
  opacity: 1;
}

.close-icon.closed {
  opacity: 0;

}

.myHtml {
  font-size: 16px;
}

.myHtml::-webkit-scrollbar {
  width: 2px !important;
}

.myHtml::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

.myHtml::-webkit-scrollbar-thumb {
  background-color: #E1C17B;
  border-radius: 4px;
  border: 2px solid #2D2921A8;
  width: 4px;
}

.myHtml {
  scrollbar-color: #E1C17B rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;
}

.tp-story-thumb {
  opacity: 0;
  transition: opacity 2s ease, transform 1s ease;
  transform: translateY(20px);
}

.thumb-active {
  opacity: 1;
  transform: translateY(0);
}

.tp-story__single {
  padding: 10px;
  font-size: 2rem;
  transition: color 0.3s ease;
}

.tp-story__single.active {
  color: #E1C17B;
}

.fade-top {
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.tp-story__single.active .fade-top {
  opacity: 0;
  transform: translateY(0);
}

.text-bordered {
  font-size: 3.5rem;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  color: transparent; /* Makes text transparent */
  -webkit-text-stroke: 2px #E1C17B; /* Border color and width */
  margin: 0;
}

.swiper-button-prev {
  color: gray !important;
  width: 10px !important;
  height: 11px !important;
  right: 20px !important;
  background: white;
  padding: 30px;
  border-radius: 20px;
}

.swiper-button-next {
  color: black !important;
  width: 10px !important;
  height: 11px !important;
  background: white;
  padding: 30px;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 5px !important;
    height: 5px !important;
    padding: 25px;
    background: transparent;
    border-radius: 15px;
    right: 10px !important;
  }

  .swiper-button-prev {
    color: lightgray !important;
  }

  .swiper-button-next {
    color: gray !important;
  }
}
